import request from '../../http'
import {managerUrl} from '../../api'

//角色列表接口
export function roleList(data){
  return request({
    url:managerUrl + "/carton/user/role/list",
    method:'post',
    data
  })
}

//新增角色接口
export function roleAdd(data){
  return request({
    url:managerUrl + "/carton/user/role/add",
    method:'post',
    data
  })
}

//编辑角色接口
export function roleEdit(data){
  return request({
    url:managerUrl + "/carton/user/role/edit",
    method:'post',
    data
  })
}

//角色对应用户列表接口
export function roleUserList(data){
  return request({
    url:managerUrl + "/carton/user/role/users",
    method:'post',
    data
  })
}

//删除角色接口
export function roleDel(data){
  return request({
    url:managerUrl + "/carton/user/role/del",
    method:'post',
    data
  })
}

//菜单列表接口
export function menuAll(data){
  return request({
    url:managerUrl + "/carton/menu/info/all",
    method:'post',
    data
  })
}

//保存角色菜单接口
export function roleMenuSave(data){
  return request({
    url:managerUrl + "/carton/menu/info/save/user",
    method:'post',
    data
  })
}

