<template>
<!-- 角色管理 -->
  <div class="zh-main-app ">
     <div class="zh-main-content userList">
      <div class="queryBoxOne">
        <div class="btnBox">
          <el-button type="primary" @click="addUser">
            <span class="text">新增用户</span>
          </el-button>
        </div>
        <div class="queryItem">
           <el-select placeholder="角色名称" class="select w240" v-model="formData.role" clearable @change="getList">
            <el-option v-for="item in roleArr" :key="item.role"  :label="item.roleName" :value="item.role"  />
          </el-select>
          <el-select placeholder="请选择仓库" class="select w240" v-model="formData.stockId" clearable @change="getList">
            <el-option v-for="item in sotckList" :key="item.stockId"  :label="item.stockName" :value="item.stockId"  />
          </el-select>
          <el-input   v-elInput class="w240" placeholder="用户名/用户账户模糊搜索" @keydown.enter="getList" v-model="formData.query" clearable>
          </el-input>

          <div class="queryBtn">
          <el-button type="primary" @click="getList"
            ><el-icon><Search /></el-icon
          ></el-button>
        </div>
        </div>
      </div>
      <div class="tabList">
        <div class="user-list">
          <div class="single cur_p" v-for="(item,index) in tableData" :key="index">
            <div class="user-name">
              <img src="https://oss.musaemotion.com/WEB/assets/img/index/userPic.png" class="pic">
              <div class="name">{{ item.userName }}</div>
            </div>
            <div class="user-desc">
              <div class="text">登录账户：</div>
              <div class="desc">{{ item.account }}</div>
            </div>
            <div class="user-desc">
              <div class="text">用户角色：</div>
              <div class="desc">{{ item.roleName }}</div>
            </div>
            <div class="user-desc">
              <div class="text">管理仓库：</div>
              <div class="user-stock-list" v-if="item.stockList">
                <div class="single" v-for="stock in item.stockList" :key="stock.stockId">{{ stock.stockName }} </div>
              </div>
            </div>
            <div class="user-control">
              <div class="control-btn" @click="editUser(item)">编辑</div>
              <div class="control-btn" @click="resetPassword(item)">密码重置</div>
              <div class="control-btn" @click="resetPhone(item)">手机号重置</div>
              <div class="control-btn">
                <el-switch v-model="item.status" :before-change="changeStatus.bind(this,item)" :active-text="item.status?'已开启':''" :inactive-text="item.status?'':'已禁用'"/>
              </div>
            </div>
            <div class="single-disabled " v-if='!item.status'>
               <img src="@/assets/img/person/disable.png"  class="dis-pic">
               <div class="text">已禁用</div>
            </div>
          </div>
        </div>
      </div>
     </div>
  </div>
  <AddUser :userInfo="userInfo" v-model="addShow" @close="closeAdd"></AddUser>
</template>

<script setup>
import AddUser from './components/addUser.vue'
import { ref, reactive, getCurrentInstance, onMounted } from 'vue'
import  { ElMessageBox } from 'element-plus'
import { Search } from "@element-plus/icons-vue";
import {stockList} from "@/utils/api/procurement/stock.js";
import {  userList ,userOpen  } from '@/utils/api/person/user';
import {  unbindPhone,resetPwd  } from '@/utils/api/person/index';
import { roleList } from '@/utils/api/person/role.js'
import {cloneDeep} from 'lodash'
const { proxy } = getCurrentInstance()
const formData = reactive({
  query: '',
  stockId:"",
  role:"",
})
const tableData = ref([])
const getList = () => {
  userList({
    query: formData.query,
    stockId: formData.stockId,
    role: formData.role,
  }).then((res) => {
    if (res.code != 0) {
     proxy.$message.error(res.msg)
      return
    }
    tableData.value = res?.data?.list || []
  })
}
const sotckList = ref([])
const roleArr = ref([]);
const getSotckList = () => {
  stockList({all:true}).then((res) => {
    if (res.code != 0) {
      proxy.$message.error(res.msg);
      return;
    }
    sotckList.value = res.data.list || [];
  });
};
//获取角色列表
const getRoleList = () => {
  roleList({}).then((res) => {
    if (res.code != 0) {
      proxy.$message.error(res.msg);
      return;
    }
    roleArr.value = res.data.list || [];
  });
};
const userInfo = reactive({
  userName:'',
  role:'',
  account:"",
  uid:0,
  stockList:[]
})
//重置userInfo
const resetUserInfo = ()=>{
  userInfo.userName = ''
  userInfo.role = ''
  userInfo.uid = 0
  userInfo.account = '';
  userInfo.stockList = []
}
//新增用户
const addUser = ()=>{
  resetUserInfo()
  addShow.value = true
}
//编辑用户
const editUser = (row)=>{
  resetUserInfo()
  userInfo.userName = row.userName
  userInfo.role = row.role
  userInfo.uid = row.uid
  userInfo.account = row.account
  userInfo.stockList = cloneDeep(row.stockList)
  addShow.value = true
}

//密码重置
const resetPassword = (row)=>{
  ElMessageBox.confirm('确认重置密码吗？(重置密码为：000000)', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    customClass: "zh-message-box",
    closeOnClickModal:false
  }).then(() => {
    resetPwd({
      uid:row.uid
    }).then((res)=>{
      if(res.code != 0){
        proxy.$message.error(res.msg)
        return
      }
      getList()
      proxy.$message.success('操作成功')
    })
  }).catch(() => {});
}

//手机号重置
const resetPhone = (row)=>{
  ElMessageBox.confirm('确认清除绑定手机号码？', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    customClass: "zh-message-box",
    closeOnClickModal:false
  }).then(() => {
    unbindPhone({
      uid:row.uid
    }).then((res)=>{
      if(res.code != 0){
        proxy.$message.error(res.msg)
        return
      }
      getList()
       proxy.$message.success('操作成功')
    })
  }).catch(() => {});
}

//切换用户启用禁用状态
const changeStatus = (row)=>{
  let text = row.status ? "确认禁用该用户？" : "确认启用该用户？";
   return ElMessageBox.confirm(text, "提示", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    customClass: "zh-message-box",
  }).then(() => {
    return new Promise((resolve,reject)=>{
      userOpen({
    uid:row.uid,
    open:!row.status
  }).then((res) => {
    if (res.code != 0) {
    proxy.$message.error(res.msg)
    return reject(false)
    }
    proxy.$message.success('操作成功')
    getRoleList()
    return resolve(true)
  }).catch(()=>{
    return reject(false)
  })
  })
  }).catch(() => {
    return false
  });
}
const addShow = ref(false)
const closeAdd = ()=>{
  addShow.value = false
  getList()
}

onMounted(() => {
  getSotckList()
  getList()
  getRoleList()
})
</script>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name:"userList",
})
</script>


<style lang="scss" scoped>
@import './index.scss';

</style>
