<template>
  <!-- 新增编辑用户 -->
  <el-dialog
    v-bind="$attrs"
    :title="userInfo.uid ? '编辑用戶' : '新增用户'"
    width="920"
    class="userInfo"
    :close-on-click-modal="false"
    destroy-on-close
    align-center
    @close="close"
    @open="openDiaAdd"
  >
    <div class="user-content">
      <div class="user-content-item dp_f">
        <div class="user-content-left single">
          <div class="title"><span class="red">*</span>用户名称</div>
          <div class="user-input">
            <el-input
              class="w360"
              maxlength="30"
              v-model="userInfo.userName"
              placeholder="请输入用户名称"
              v-elInput
            ></el-input>
          </div>
        </div>
        <div class="user-content-right single">
          <div class="title"><span class="red">*</span>用户角色</div>
          <div class="user-input">
            <el-select placeholder="角色名称" v-model="userInfo.role" class="w360">
              <el-option
                v-for="item in roleArr"
                :key="item.role"
                :label="item.roleName"
                :value="item.role"
              />
            </el-select>
          </div>
        </div>
      </div>
      <div class="user-content-item dp_f">
        <div class="user-content-left single">
          <div class="title"><span class="red">*</span>管理仓库</div>
          <div class="user-input">
            <el-select
              placeholder="仓库名称"
              class="w360 stock"
              multiple
              collapse-tags
              v-model="userInfo.stockList"
              value-key="stockId"
            >
              <el-option
                v-for="item in sotckList"
                :key="item.stockId"
                :label="item.stockName"
                :value="item"
              />
            </el-select>
          </div>
        </div>
        <div class="user-content-right single">
          <div class="title"><span class="red">*</span>登录账户</div>
          <div class="user-input">
            <el-input
            v-elInput
              class="w360 "
              maxlength="20"
              v-model="userInfo.account"
              placeholder="英文字母或数字，或字母+数字组合，最长20位"
              :disabled="userInfo.uid"
              @blur="userInfo.account = $event.target.value?.replace(/[^a-zA-Z0-9_]/g,'')"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="user-content-item dp_f">
        <div class="user-content-left single">
          <div class="title"><span class="red">*</span>管理密码</div>
          <div class="user-input">
            <el-input
            v-elInput
              class="w360"
              maxlength="20"
              disabled
              placeholder="默认密码(000000)"
            ></el-input>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <el-button class="btn1 zh-btn" @click="close">取消</el-button>
      <el-button class="btn1" type="primary" :disabled="!isOk" @click="handleOK">保存</el-button>
    </template>
  </el-dialog>
</template>

<script setup>
import {stockList} from "@/utils/api/procurement/stock.js";
import { roleList } from '@/utils/api/person/role.js'
import {  userAdd ,userEdit  } from '@/utils/api/person/user';
import {
  ref,
  toRefs,
  getCurrentInstance,
  onMounted,
  computed,
} from "vue";
const { proxy } = getCurrentInstance();
const props = defineProps({
  userInfo: {
    type: Object,
    default: () =>  {
      return{
        userName:'',
        role:'',
        account:"",
        uid:0,
        stockList:[]
      }
    },
  },
});
const { userInfo } = toRefs(props);
const emit = defineEmits(["close"]);
const sotckList = ref([]);
const roleArr = ref([]);
const getSotckList = () => {
  stockList({
    status:userInfo.value.uid ? false : true,
    all:true
  }).then((res) => {
    if (res.code != 0) {
      proxy.$message.error(res.msg);
      return;
    }
    sotckList.value = res.data.list || [];
  });
};
//获取角色列表
const getRoleList = () => {
  roleList({}).then((res) => {
    if (res.code != 0) {
      proxy.$message.error(res.msg);
      return;
    }
    roleArr.value = res.data.list || [];
  });
};

//确认新增/编辑
const handleOK = ()=>{
  let api = userInfo.value.uid? userEdit : userAdd;
  let list =  [];
  userInfo.value.stockList.forEach((item)=>{
    sotckList.value.forEach((item2)=>{
      if(item == item2.stockId || item.stockId == item2.stockId){
        list.push(item2)
      }
    })
  })
  let obj = {
    userName: userInfo.value.userName,
    role: userInfo.value.role,
    account: userInfo.value.account,
    stockList: list,
    uid:userInfo.value.uid || undefined
  }
  api(obj).then((res)=>{
    if (res.code != 0) {
      proxy.$message.error(res.msg);
      return;
    }
    if(userInfo.value.uid){
      proxy.$message.success('编辑成功')
    }else {
      proxy.$message.success('新增成功')
    }
    emit('close')
  })
}
const isOk = computed(()=>{
  return userInfo.value.userName && userInfo.value.role && userInfo.value.stockList.length > 0 && userInfo.value.account
})

const close = ()=>{
  emit('close')
}
const openDiaAdd = ()=>{
  getSotckList();
}
onMounted(() => {
  getRoleList()
});
</script>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "addUser",
});
</script>

<style lang="scss">
.userInfo {
  .user-content {
    padding: 16px;
    .user-content-item {
      align-items: flex-start !important;
      margin-bottom: 32px;
      .stock {
        color: #fff;
        &{
          .el-select__tags-text{
            color: $fsColor;
            font-size: 14px;
          }
        }
      }
       .title {
          font-size: 14px;
          color: #737480;
          margin-bottom: 10px;
        }
      .user-input-textarea {
        height: 72px;
      }
    }
  }
}
</style>
