import request from '../../http'
import {managerUrl} from '../../api'


//用户列表接口
export function userList(data){
  return request({
    url:managerUrl + "/carton/user/info/list",
    method:'post',
    data
  })
}

//新增用户
export function userAdd(data){
  return request({
    url:managerUrl + "/carton/user/info/add",
    method:'post',
    data
  })
}

//编辑用户
export function userEdit(data){
  return request({
    url:managerUrl + "/carton/user/info/edit",
    method:'post',
    data
  })
}

//启用禁用用户
export function userOpen(data){
  return request({
    url:managerUrl + "/carton/user/info/open",
    method:'post',
    data
  })
}
